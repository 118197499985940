<template>
    <section class="invoice-preview-wrapper">
        <b-overlay :show="loading" blur="2px" variant="transparent" rounded="lg" opacity="0.85">
            <b-row>
                <b-col cols="12" md="8">
                    <b-card no-body class="invoice-actions">
                        <b-card-body>
                            <b-row>
                                <b-col cols="12" md="4">
                                    <b-form-group label="Almacén" label-for="warehouse" label-class="font-weight-bold">
                                        <v-select id="warehouse" v-model="filter.wareHouse_Id" :options="wareHouses"
                                            label="nombre" :reduce="option => option.almacen_Id" :clearable="false"
                                            :searchable="false" class="select-size-sm"
                                            placeholder="Selecciona una opción">
                                        </v-select>
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="6">
                                    <b-form-group label="Agrupar Resultados" label-class="font-weight-bold">
                                        <b-form-radio-group
                                            v-model="filter.option"
                                            :options="optionGroups"
                                            name="radio-inline"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col cols="12" md="6">
                                    <!-- <b-form-group label="Filtrar por folio, cliente, almacen" label-class="font-weight-bold"> -->
                                        <b-form-input v-model="filter.searchText" size="sm" placeholder="Filtrar por folio, cliente, almacén" />
                                    <!-- </b-form-group> -->
                                </b-col>

                                <b-col cols="12" md="6">
                                    <b-button :disabled="loading" @click="getArticlesToBeDelivered" size="sm" variant="primary" class="mr-1">
                                        <feather-icon icon="SearchIcon" /> Buscar
                                    </b-button>

                                    <b-button size="sm" @click="printReport" variant="secondary"
                                        v-ripple.400="'rgba(255, 255, 255, 0.15)'">
                                        <feather-icon icon="DownloadIcon" />
                                        Descargar
                                    </b-button>
                                </b-col>
                            </b-row>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" md="12">
                    <b-card no-body>
                        <div class="table-responsive">
                            <div class="position-relative b-table-responsive mb-0 text-small small table-responsive"
                            :class="`${articles.length > 10 ? 'b-table-sticky-header' : ''}`">
                                <table ref="articlesTable" class="table b-table table-sm b-table-no-border-collapse text-small small b-table-small b-table-selectable b-table-select-single">
                                    <thead>
                                        <tr>
                                            <th style="font-size: 10px!important;" v-for="column in currentColumns" :key="column">{{ column }}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-for="(items, index) in articleDetails" :key="index">
                                       <tr v-if="filter.option === 'folio'">
                                            <th style="font-size: 10px!important;">{{ index }}</th>
                                            <th style="font-size: 10px!important;" class="text-capitalize">{{ formatDate(articleDetails[index][0].fecha_Factura) }}</th>
                                            <th style="font-size: 10px!important;">{{ articleDetails[index][0].cliente }}</th>
                                            <th style="font-size: 10px!important;">{{ articleDetails[index][0].almacen }}</th>
                                            <th></th>
                                            <th colspan="6"></th>
                                        </tr>

                                        <tr v-if="filter.option === 'cliente'">
                                            <th colspan="4">{{ index }}</th>
                                            <th colspan="7"></th>
                                        </tr>

                                        <tr v-if="filter.option === 'articulo'">
                                            <th style="font-size: 10px!important;">{{ index }}</th>
                                            <th style="font-size: 10px!important;">{{ articleDetails[index][0].clave_Articulo }}</th>
                                            <th style="font-size: 10px!important;">{{ articleDetails[index][0].almacen }}</th>
                                            <th colspan="8"></th>                                            
                                        </tr>

                                        <tr v-if="filter.option === 'folio'" v-for="(item, idx) in items" :key="index+idx">                                                                                        
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td style="font-size: 10px!important;">{{ item.articulo }}</td>
                                            <td style="font-size: 10px!important;">{{ item.clave_Articulo }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.um_Total }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.entregado }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.devoluciones }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.pendiente }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.estatus }}</td>
                                        </tr>

                                        <tr v-if="filter.option === 'cliente'" v-for="(item, idx) in orderBy(items, 'fecha_Factura', 'desc')" :key="index+idx">                                                                                        
                                            <td></td>
                                            <td style="font-size: 10px!important;">{{ item.folio }}</td>
                                            <td style="font-size: 10px!important;">{{ formatDate(item.fecha_Factura) }}</td>
                                            <td style="font-size: 10px!important;">{{ item.almacen }}</td>
                                            <td style="font-size: 10px!important;">{{ item.articulo }}</td>
                                            <td style="font-size: 10px!important;">{{ item.clave_Articulo }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.um_Total }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.entregado }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.devoluciones }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.pendiente }}</td>
                                            <td style="font-size: 10px!important;" class="text-center">{{ item.estatus }}</td>
                                        </tr>

                                        <tr v-if="filter.option === 'articulo'" v-for="(item, idx) in orderBy(items, 'fecha_Factura', 'desc')" :key="index+idx">                                                                                        
                                            <td colspan="3"></td>
                                            <td style="font-size: 10p!important;">{{ item.cliente }}</td>
                                            <td style="font-size: 10p!important;">{{ item.folio }}</td>
                                            <td style="font-size: 10p!important;">{{ formatDate(item.fecha_Factura) }}</td>
                                            <td style="font-size: 10p!important;" class="text-center">{{ item.um_Total }}</td>
                                            <td style="font-size: 10p!important;" class="text-center">{{ item.entregado }}</td>
                                            <td style="font-size: 10p!important;" class="text-center">{{ item.devoluciones }}</td>
                                            <td style="font-size: 10p!important;" class="text-center">{{ item.pendiente }}</td>
                                            <td style="font-size: 10p!important;" class="text-center">{{ item.estatus }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </b-card>
                </b-col>
            </b-row>

        </b-overlay>
    </section>
</template>

<script>
import {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardText,
    BCardBody,
    BTable,
    BTableLite,
    BOverlay,
    BSpinner,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BFormGroup,
    BFormSelect,
    BBadge,
    BFormRadioGroup,
    BPagination,
    BTooltip, 
    VBTooltip,

} from 'bootstrap-vue'
import { ref, onMounted, computed, watch, reactive, defineEmits, nextTick } from "@vue/composition-api";
import store from '@/store'
import flatPickr from "vue-flatpickr-component";
import { Spanish } from "flatpickr/dist/l10n/es.js";
import * as helper from '@/libs/helpers';
import vSelect from "vue-select";
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex';
import moment from 'moment';
import * as XLSX from 'xlsx';

import ReportsProvider from '@/providers/Reports';
const ReportsResource = new ReportsProvider();

import DeliveryRoutesProvider from '@/providers/DeliveryRoutes'
const DeliveryRoutesResource = new DeliveryRoutesProvider();

import WebOperadoresProvider from '@/providers/WebOperadores';
const WebOperadoresResource = new WebOperadoresProvider();

import WebCamionesProvider from '@/providers/WebCamiones';
const WebCamionesResource = new WebCamionesProvider();

export default {
    name: "RoutingLogs",
    components: {
        BCard,
        BCardTitle,
        BCardBody,
        BCardHeader,
        BCardText,
        BTable,
        BTableLite,
        BOverlay,
        BSpinner,
        BRow,
        BCol,
        BButton,
        BFormInput,
        BInputGroup,
        BInputGroupPrepend,
        BFormGroup,
        BFormSelect,
        BBadge,
        BFormRadioGroup,
        BPagination,
        BTooltip, 
        VBTooltip,

        //
        flatPickr,
        vSelect,
    },
    directives: {
        Ripple,
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            columns: {
                folio: ["FOLIO", "FECHA", "CLIENTE", "ALMACÉN", "ARTÍCULO", "CLAVE ARTÍCULO", "UNIDADES", "ENTREGADO", "DEVOLUCIÓN", "POR ENTREGAR", "ESTATUS"],
                cliente: ["CLIENTE", "FOLIO", "FECHA", "ALMACÉN", "ARTÍCULO", "CLAVE ARTÍCULO", "UNIDADES", "ENTREGADO", "DEVOLUCIÓN", "POR ENTREGAR", "ESTATUS"],
                articulo: ["ARTÍCULO", "CLAVE ARTÍCULO", "ALMACÉN", "CLIENTE", "FOLIO", "FECHA", "UNIDADES", "ENTREGADO", "DEVOLUCIÓN", "POR ENTREGAR", "ESTATUS"]
            }, 
        }
    },
    computed: {
        ...mapGetters({
          getWareHouses: 'auth/getWareHouses',
          logisticSetting: 'auth/getLogisticSetting',
        }),
        showColumnOrigin() {
            return this.findSetting('ACTIVAR_COLUMNA_ORIGEN_LOGISTICA') || false
        },
        currentColumns() {
            return this.columns[this.filter.option];
        },
        articleDetails() {
            const option = this.optionGroups.find(o => o.value === this.filter.option);

            // Hacer la búsqueda según el texto ingresado en filter.searchText
            let results = this.filter.searchText
                ? this.articles.filter(item =>
                    item.folio.toLowerCase().includes(this.filter.searchText.toLowerCase()) || 
                    item.cliente.toLowerCase().includes(this.filter.searchText.toLowerCase()) || 
                    item.almacen.toLowerCase().includes(this.filter.searchText.toLowerCase()) || 
                    item.articulo.toLowerCase().includes(this.filter.searchText.toLowerCase())
                )
                : this.articles;

            // Ordenar los resultados según el campo y orden especificado
            const orderResult = _.orderBy(results, [option.order], [option.desc ? 'desc' : 'asc']);

            // Agrupar los resultados por el valor de la opción seleccionada
            return _.groupBy(orderResult, option.value);
        },

    },
    created() {
        this.loadWareHouses();
    },
    methods: {
        orderBy(items, column, order) {
            return _.orderBy(items, [column], [order]);
        },
        groupKey(items) { // Devuelve la clave de agrupación (por ejemplo, fecha o folio)
            if (this.filter.option === 'folio') return items[0].fecha_Factura;
            if (this.filter.option === 'cliente') return items[0].cliente;
            if (this.filter.option === 'articulo') return items[0].articulo;
        },        
        groupValue(items, key) {  // Devuelve el valor de una propiedad agrupada (por ejemplo, cliente o almacén)
            return items[0][key] || '';
        },
        // Devuelve el valor dinámico para una columna específica
        getColumnData(item, column) {
            const mapping = {
                "Folio": item.folio,
                "Fecha": this.formatDate(item.fecha_Factura),
                "Cliente": item.cliente,
                "Almacén": item.almacen,
                "Artículo": item.articulo,
                "Clave Artículo": item.clave_Articulo,
                "Unidades": item.um_Total,
                "Entregado": item.entregado,
                "Devolución": item.devoluciones,
                "Por Entregar": item.pendiente,
                "Estatus": item.estatus
            };
            return mapping[column] || '';
        },
        loadWareHouses() {
            // Obtener la lista de almacenes del store
            const wareHousesFromStore = this.getWareHouses;

            // Crear el nuevo objeto de almacén
            const allWareHousesOption = {
                almacen_Id: 0,
                nombre: 'TODAS'
            };

            // Agregar el nuevo objeto al inicio de la lista
            this.wareHouses = [allWareHousesOption, ...wareHousesFromStore];
        },
        printReport() {
            const table = this.$refs.articlesTable;
            const wb = XLSX.utils.table_to_book(table);
            XLSX.writeFile(wb, 'Articulos-Por-Entregar.xlsx');
        }
    },
    setup() {

        const loading = ref(false)
        const search = ref(null)
        const articles = ref([])
        const searchText = ref('')

        const optionGroups = ref([
            { text: 'Por Factura', value: 'folio', order: 'fecha_Factura', desc: true },
            { text: 'Por Cliente', value: 'cliente', order: 'cliente', desc: false },
            { text: 'Por Artículos', value: 'articulo', order: 'articulo', desc: false },
        ])

        const filter = ref({          
            status: 'TODAS',
            wareHouse_Id: 0, // Almacen Venta
            option: 'folio',
        })
        const wareHouses = ref([])

        const getArticlesToBeDelivered = async ()  =>{

            articles.value = []
            const query = {
                almacenId: filter.value.wareHouse_Id,
                status: filter.value.status === 'TODAS' ? 'ABIERTA, EN RUTA, CLIENTE PASA' : filter.value.status,
            }
            
            try {
                loading.value = true
                const { data } = await ReportsResource.getArticlesToBeDelivered(query)
                loading.value = false
                if(data.isSuccesful && data.data.length > 0) {
                    // const option = optionGroups.value.find(o => o.value === filter.value.option)
                    // const result = _.orderBy(data.data, [option.order], [option.desc ? 'desc' : 'asc'])                    
                    articles.value = data.data //_.groupBy(result, option.value)
                } else {
                    helper.danger(data.message)
                }

                console.log(articles.value)
            }catch(e) {
                loading.value = false
                helper.handleResponseErrors(e)
            }finally {
                loading.value = false
            }
        }       

        const selectRow = (item, index) => {
           
        }

        const isSelected = (index) => {
           
        }


        return {
            search,
            loading,  
            filter,
            wareHouses,
            articles,
            searchText,
            optionGroups,
            
            //methods
            selectRow,
            isSelected,
            getArticlesToBeDelivered,
        };
    }
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/vue/libs/vue-sweetalert.scss";
@import "~@core/scss/vue/libs/vue-select.scss";
@import '~@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@media print {

    // Global Styles
    body {
        background-color: transparent !important;
        font-size: 8pt !important;
    }

    nav.header-navbar {
        display: none;
    }

    .main-menu {
        display: none;
    }

    .header-navbar-shadow {
        display: none !important;
    }

    .content.app-content {
        margin-left: 0;
        padding-top: 2rem !important;
    }

    footer.footer {
        display: none;
    }

    .card {
        background-color: transparent;
        box-shadow: none;
    }

    .customizer-toggle {
        display: none !important;
    }

    // Invoice Specific Styles
    .invoice-preview-wrapper {



        // Action Right Col
        .invoice-actions {
            display: none;
        }
    }
}
</style>


<style>

.flatpickr-small .flatpickr-input {
    /*font-size: 8px!important; /* Ajusta el tamaño del texto del input */
    padding: 5px;
    /* Ajusta el padding del input */
    /*width: 120px; /* Ajusta el ancho del input */
}

.flatpickr-input {
    /*width: 150px!important; /* Ajusta el ancho del input */
    height: 30px !important;
    /* Ajusta la altura del input */
    /*font-size: 7px!important; /* Ajusta el tamaño del texto del input */
}

.b-table-sticky-header {
    overflow-y: auto;
    /* max-height: 480px; */
    max-height: calc(100vh - 160px)!important;
}
</style>